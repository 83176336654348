import React from "react"
import styled from "styled-components"
import Box from "@components/styled/Box"
import { rem } from "@src/theme"

class FilterActionButtons extends React.PureComponent {
  render() {
    return (
      <Box display="flex">
        <Button
          as="button"
          color="blue"
          mr={4}
          onClick={this.props.onAdd}
          disabled={this.props.addDisabled}
        >
          Add filter
        </Button>
        <Button as="button" color="red" onClick={this.props.onClear}>
          Clear filters
        </Button>
      </Box>
    )
  }
}

const Button = styled(Box)`
  font-size: ${rem(14)};
  padding-bottom: 2.5px;
  border-bottom: 1px solid currentColor;
`

export default FilterActionButtons
