import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"
import Box from "@components/styled/Box"
import Container from "@components/styled/Container"
import Layout from "@components/layout"
import SEO from "@components/seo"
import { rem } from "@src/theme"
import ArticleCard from "@components/ArticleCard"
import Columns from "@components/styled/Columns"
import Column from "@components/styled/Column"
import SelectInput from "@components/Select"
import { fetchArticles } from "@helpers/airtable"
import TextInput from "@components/TextInput"
import { SingleDatePicker } from "react-dates"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
// ****************//
import FilterActionButtons from "@components/FilterActionButtons"
import {
  getValueFromId,
  mapOptions,
  getFilterCallbacks,
  getFilterOptions,
  getUnselectedFilterOptions
} from "@helpers/data"
import { ClipLoader } from "react-spinners"
import { authCheckRedirect } from "@helpers/auth"
import AddButton from "@components/AddButton"

const FILTER_BY_OPTIONS = [
  { value: "name", label: "Article name" },
  { value: "publisher", label: "Publisher" },
  { value: "author", label: "Author" },
  { value: "date", label: "Published date" },
  { value: "type", label: "Article form" }
]

const ViewArticles = ({ data, ...props }) => {
  const [recordsData, setRecordsData] = useState([])
  const [filters, setFilters] = useState([{ type: "name", value: "" }])
  const [loading, setLoading] = useState(false)
  const [publishedCalendarFocused, setPublishedCalendarFocused] = useState(
    false
  )

  const articleTypeOptions = mapOptions(data.airtable_articleType.edges)
  useEffect(() => {
    authCheckRedirect()
    const fetchData = async () => {
      const { airtable_articleType } = data
      setLoading(true)
      let dataFromAirtable = await fetchArticles()
      dataFromAirtable = dataFromAirtable.map(record => ({
        id: record.id,
        name: record.fields["Article Name"] || "",
        publisher: record.fields["Publisher"] || "",
        author: record.fields["Author"] || "",
        date: record.fields["Published Date"] || "",
        type: getValueFromId(
          airtable_articleType.edges,
          record.fields["Article Type"]
        )
      }))
      setRecordsData(dataFromAirtable)
      setLoading(false)
    }

    fetchData()
  }, [])

  // ****************//
  const onFilterByChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      type: val,
      value: ""
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const onFilterChoiceChange = (val, index) => {
    let updatedFilters = [...filters]

    updatedFilters[index] = {
      ...updatedFilters[index],
      value: val
    }
    setFilters(updatedFilters)
  }
  // ****************//
  const handleTextFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }
  // ****************//
  const handleDropdownFilterChange = (val, index) => {
    onFilterChoiceChange(val, index)
  }

  const getDropdownOption = type => {
    if (type === "type") {
      return articleTypeOptions
    }

    return []
  }

  // ****************//
  const addFilter = () => {
    const availableOptions = getUnselectedFilterOptions(
      filters,
      FILTER_BY_OPTIONS
    )
    setFilters([...filters, { type: availableOptions[0].value, value: "" }])
  }
  // ****************//
  const clearFilters = () => {
    setFilters([{ type: "name", value: "" }])
  }

  const renderFilterInput = (type, value, index) => {
    if (type === "date") {
      return (
        <SingleDatePicker
          id="publishedDate_input"
          date={value}
          focused={publishedCalendarFocused}
          onDateChange={e => handleTextFilterChange(e, index)}
          onFocusChange={e =>
            setPublishedCalendarFocused(!publishedCalendarFocused)
          }
          numberOfMonths={1}
          isOutsideRange={() => false}
        />
      )
    }
    if (type !== "type") {
      return (
        <div>
          <label htmlFor="name-filter"></label>
          <TextInput
            name="name-filter"
            value={value}
            onChange={e => handleTextFilterChange(e.target.value, index)}
            onBlur={() => {}}
          />
        </div>
      )
    }

    return (
      <SelectInput
        name={type}
        value={value}
        onChange={(name, val) => handleDropdownFilterChange(val, index)}
        options={getDropdownOption(type)}
      />
    )
  }

  return (
    <Layout uri={props.uri}>
      <SEO title="Home" />

      <Container pt={7}>
        <Box
          as="h1"
          fontSize={rem(28)}
          mb={4}
          p={4}
          pr={6}
          bg="blue"
          color="white"
          display="inline-block"
        >
          Articles
        </Box>

        <Box as="section" my={6}>
          <Box display="flex" alignItems="flex-end">
            <Box mr={5}>
              <Box display="flex" alignItems="center" mb={3}>
                <Box as="label" display="block" mr={4}>
                  Filter by
                </Box>

                <FilterActionButtons
                  onAdd={addFilter}
                  onClear={clearFilters}
                  addDisabled={filters.length === FILTER_BY_OPTIONS.length}
                />
              </Box>

              {filters.map(({ type, value }, index) => (
                <Box width="100%" display="flex" mb={5}>
                  <Box width={rem(300)} mr={5}>
                    <SelectInput
                      name={type}
                      value={type}
                      onChange={(name, val) => onFilterByChange(val, index)}
                      options={getFilterOptions(filters, FILTER_BY_OPTIONS)}
                    />
                  </Box>
                  <Box width={rem(300)}>
                    {renderFilterInput(type, value, index)}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
        <AddButton to="/add-article" />
        <Columns>
          {recordsData.length ? (
            getFilterCallbacks(filters, recordsData, [
              "name",
              "publisher",
              "author"
            ])
              .reduce((d, f) => d.filter(f), recordsData)
              .map(record => (
                <Column key={record.id} width={[1, 1 / 2]} mb={2}>
                  <ArticleCard {...record} />
                </Column>
              ))
          ) : !loading && !recordsData.length ? (
            <Column width={1}>
              <Box textAlign="center">
                There aren't any articles, add one{" "}
                <Link to="add-article">here</Link>
              </Box>
            </Column>
          ) : (
            <Column width={1}>
              <Box display="flex" justifyContent="center">
                <ClipLoader sizeUnit="px" size={150} />
              </Box>
            </Column>
          )}
        </Columns>
      </Container>
    </Layout>
  )
}

export default ViewArticles

export const query = graphql`
  query {
    airtable_articleType: allAirtable(
      filter: { table: { eq: "DL_Article_Type" } }
    ) {
      edges {
        node {
          recordId
          data {
            Name
          }
        }
      }
    }
  }
`
